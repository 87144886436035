<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "user-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <ul class="clebex-item-section pill" v-if="containers && containers.length">
      <li
        class="clebex-item-section-item"
        v-for="container in containers"
        :key="container.id"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  type="checkbox"
                  @change="setAllowedContainer(container.id)"
                  :id="container.id"
                  :value="container.id"
                  :name="container.id"
                  :checked="isChecked(container.id)"
                />
                <label :for="container.id"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ container.name }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </mobile-screen>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
export default {
  name: "AllContainers",
  computed: {
    ...mapState("reception", ["containers", "allowedContainers"]),
    containersList() {
      if (this.containers && this.containers.length) {
        return this.containers;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("reception", ["getContainers", "setAllowedContainer"]),
    isChecked(id) {
      if (
        this.allowedContainers &&
        typeof this.allowedContainers !== undefined
      ) {
        return this.allowedContainers.find(el => el.id === id) ? true : false;
      }
      return false;
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
